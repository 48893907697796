import React from 'react';
import BlogArticle from '../../../components/common/BlogArticle';
import SEO from '../../../components/SEO';

import { useScrollToTop } from '../../../hooks/useScrollToTop';
import blogImage from '../../../assets/img/blog/blog-5.jpeg';

import './BlogArticlePage.scss';

interface BlogData {
    id: number;
    title?: string;
    subtitle?: string;
    text: string;
    type: string;
    subtype: string;
}

interface BlogItems extends Array<BlogData> {}

const BlogArticlePage = () => {
    const contentData = [
        {
            id: 0,
            text: 'My Experience with AI-Assisted Development',
            type: 'margin',
            subtype: 'blog-content',
        },
        {
            id: 1,
            text: 'Challenges and Adaptations',
            type: 'margin',
            subtype: 'blog-content',
        },
        {
            id: 2,
            text: 'Best Practices for AI-Assisted Development',
            type: 'margin',
            subtype: 'blog-content',
        },
        {
            id: 3,
            text: 'Conclusion: The Future of AI in MVP Development',
            type: 'margin',
            subtype: 'blog-content',
        },
    ];
    const blogData: BlogItems = [
        {
            id: 0,
            text:
                `Building MVPs is a classic and well-known process. At Blackbird Labs, we've been developing MVPs and prototypes for our clients for seven years. A typical MVP development takes one to three months, involving one or two full-stack developers, a product manager, and sometimes a designer. Looking at our last five MVPs, they typically require at least 500 billable hours and cost clients anything from $15,000 USD. When you add another $15,000 for business development and marketing, the barrier to entry for tech entrepreneurship gets quite high. I usually don’t recommend our clients go into new ventures without at least a $50,000 budget and another $50,000 in soft commitments. This meant launching small new products wasn’t for everyone—until last year.`,
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            title: 'My Experience with AI-Assisted Development',
            text:
                `A little about myself: I'm a software engineer with over a decade of experience building products. These days, I mostly manage projects for our clients, but I never stopped coding. While I can build practically anything, I’m a little rusty since I no longer spend 100 hours a month in Visual Studio Code.`,
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            text:
                `Recently, I decided to develop a prototype for one of our clients myself. At Blackbird Labs, we established an AI strategy early on, encouraging developers to use tools like Copilot daily. These tools have matured quickly, with intense competition driving innovation.`,
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            text:
                `So, I downloaded Cursor and got to work. Without diving into business specifics, the app I built was simple, featuring:`,
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'A mobile-first web interface',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'A RESTful API that connects with a third-party service',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'SMS authentication',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'Cloud deployment',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'I chose the following tech stack:',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'React',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'Express',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'AWS & Cognito',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 1,
            text:
                'In under 20 hours, I generated 2,000 lines of code—without manually touching a single file. The process was straightforward: instruct AI to generate the code with as much specificity as possible, review the result, build and run it, and continue iterating. Cursor offers several AI models, but I found the standard Claude 3.5 model to be pretty solid. The code it generates is at a strong junior to early intermediate level—more than enough to get the app working.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            text:
                `Yes, it made mistakes. Sometimes, the generated code wouldn’t compile or wouldn’t work correctly. But I followed a simple rule: I tried not to modify the generated files myself, allowing the AI to rework them instead. This approach paid off. When I manually adjusted the code, the AI would often misunderstand my changes, creating future problems. I found that maintaining AI-friendly code was key to keeping the AI productive.`,
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 2,
            title: 'Challenges and Adaptations',
            text:
                `Of course, none of this would work if I didn’t know exactly what I was doing. Issues started about five minutes in: the AI-generated sign-in code for Cognito used an outdated library and incorrect parameters. A quick look at the documentation allowed me to redirect the AI toward a better approach.`,
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 2,
            text:
                `Another example: The third-party API I was integrating used SOAP, and AI decided to generate a full-scale SOAP client with marshalling, unmarshalling, and detailed error handling—completely overkill for an MVP. I redirected it to generate a simple HTTP call with a parameterized string literal as the body.`,
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 2,
            text:
                'As the codebase grew, I continued adding features while barely touching the code myself. Instead, I interacted with the AI using precise prompts:',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 2,
            text:
                '"Go to this file and throw an error in this method if this parameter is not present."',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 2,
            text:
                '"In this file, catch the error and propagate it to the React Router, wrapping it in this interface."',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 2,
            text: '"Extract this piece of CSS into a variable and apply it to component ABC."',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 2,
            text:
                'Throughout development, I kept the backend and frontend running so I could validate results in real-time, adjust APIs, move business logic between components without breaking functionality, and add tests.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 2,
            text:
                'In the end, I easily deployed everything to the dev environment and sent the link to the customer. My assessment? AI tripled the speed of development without major drawbacks. Adding features to this MVP will also be easy—assuming the same AI-assisted workflow continues.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            title: 'Best Practices for AI-Assisted Development',
            text: 'Here are my simple rules for getting good results from AI-assisted development:',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text: '1. Use an integrated development environment.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text: '2. Let AI generate all or most of the code.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text:
                '3. Clearly understand your building blocks and instruct AI to create them one by one.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text:
                '4. Design APIs and data models in a way that AI can generate them without having to guess.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text: '5. Review generated changes before accepting them.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text: '6. Avoid switching between AI models within the same building blocks.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text: '7. Test intermediate results after each prompt.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            title: 'Conclusion: The Future of AI in MVP Development',
            text:
                'AI has significantly reduced the cost and time required to develop MVPs and prototypes. This shift will have a long-lasting impact on the industry, enabling founders to enter the market faster, take more ideas from 0 to 1, and compete more effectively. Years ago, outsourcing had a similar effect on software development, reducing costs and allowing small companies to challenge enterprise giants.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            text:
                'I encourage founders to embrace this shift—starting with a smaller budget is now more feasible. Tech co-founders, in particular, should consider building their MVPs themselves before assembling teams. We may be entering a new era reminiscent of an earlier time—an era where a single engineer can build and launch beautiful software independently.',
            type: 'mb',
            subtype: 'blog',
        },
    ];

    useScrollToTop();

    return (
        <div>
            <SEO
                pageTitle="Blackbird Lab - Blog"
                pageDescription="Accelerating MVP Development & Prototyping with AI Assistants"
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/blog/articles/unlocking-the-power-of-managed-service.png"
            />
            <BlogArticle
                title="Accelerating MVP Development & Prototyping with AI Assistants"
                image={blogImage}
                author="Yurii Drozd"
                date="March 20, 2025"
                blogData={blogData}
                contentData={contentData}
            />
        </div>
    );
};

export default BlogArticlePage;
